<template>
  <ProfileCard
    :name="state.data.name"
    :credits="state.data.credits"
    :tokens="state.data.tokens"
  />

  <form @submit.prevent="handleSubmit(!v$.$invalid)" class="_form _relative">
    <Loader isOverlay v-if="state.isLoading" />
    <label class="p-field mb-4" :class="{'p-invalid': v$.email.$invalid && v$.email.$dirty}">
      <span class="p-label"> <span v-t="'email'" /> <span class="p-required">*</span></span>
      <InputText
        type="text"
        v-model="v$.email.$model"
        @blur="v$.email.$touch()"
      />
    </label>
    <label class="p-field mb-4" :class="{'p-invalid': v$.name.$invalid && v$.name.$dirty}">
      <span class="p-label"> <span v-t="'company_page.contact_person'" /> <span class="p-required">*</span></span>
      <InputText
        type="text"
        v-model="v$.name.$model"
        @blur="v$.name.$touch()"
      />
    </label>
    <label class="p-field mb-4" :class="{'p-invalid': v$.phone.$invalid && v$.phone.$dirty}">
      <span class="p-label"> <span v-t="'company_page.phone'" /> <span class="p-required">*</span></span>
      <InputText
        type="text"
        v-model="v$.phone.$model"
        @blur="v$.phone.$touch()"
      />
    </label>
    <label class="p-field mb-4">
      <span class="p-label" v-t="'company_page.credits'"></span>
      <InputNumber type="text" v-model="v$.credits.$model" />
    </label>
    <label class="p-field mb-4">
      <span class="p-label" v-t="'company_page.available_tokens'"></span>
      <InputNumber type="text" v-model="v$.tokens.$model" />
    </label>

    <div class="grid">
      <Button class="p-button-big col mt-3 ml-2 mr-2" type="submit" :disabled="state.isSubmiting"><span v-t="'save_change'"></span></Button>
      <Button class="p-button-big p-button-outlined p-button-gray p-button-icon-right col mt-3 ml-2 mr-2" @click="toggleBlock">
        <span v-t="state.data.is_active ?  'unlock' : 'block'"></span>
        <Icon :name="state.data.is_active ? 'lock' : 'unlock'" />
      </Button>
    </div>
  </form>
</template>

<script>

import { computed } from 'vue';
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

import { Loader, Icon } from '../../../../components/ui'
import { ProfileCard } from '../../../../components/common'

export default {
  components: {
    ProfileCard,
    InputNumber,
    InputText,
    Loader,
    Button,
    Icon,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const state = store.state.companyStafUser;
    const data = computed(() => state.data);

    const rules = {
      title: { required },
      email: { required, email },
      name: { required },
      phone: { required },
      address: { required },
      credits: {  },
      tokens: {  },
      is_active: {  },
    }

    const v$ = useVuelidate(rules, data)

    store.dispatch('companyStafUser/reqBase', { user: route.params.companyMemberID })

    const handleSubmit = (isFormValid) => {
      v$.value.$touch()
      if (!isFormValid) {
        return;
      }
      store.dispatch('companyStafUser/sendBase', { body: data.value, toast, t })
    }

    const toggleBlock = () => {
      store.dispatch('companyStafUser/reqToggleBlock')
    }

    return {
      v$,
      state,
      handleSubmit,
      toggleBlock,
    }
  }
}
</script>
